<template>
  <div class="contents detailWrap">
    <div class="title flexB">
      <h2>{{ editMode ? "디바이스 정보" : "디바이스 등록" }}</h2>
    </div>
    <div class="box one">
      <div class="flexL">
        <div class="flex" v-if="editMode">
          <p>등록일</p>
          <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flex">
          <p>농장주</p>
          <input type="text" v-model="farmer.username" @click="handleModal" />
          <button class="gray small" @click="handleModal">검색</button>
        </div>
        <div class="flex">
          <p>농장주소</p>
          <p v-if="farm">{{ farm.address }}</p>
          <select v-model="farmId" v-else>
            <option value="" disabled>선택</option>
            <option v-for="(data, i) in farmList" :key="i" :value="data._id">
              {{ data.address }}
            </option>
          </select>
        </div>
        <div class="flex">
          <p>ID</p>
          <input type="text" v-model="flag" />
        </div>
        <div class="flex">
          <p>MAC RN400</p>
          <input type="text" v-model="mac_rn400" />
        </div>
        <div class="flex">
          <p>MAC RN171</p>
          <input type="text" v-model="mac_rn171" />
        </div>
        <div class="flex">
          <p>API Key</p>
          <input type="text" v-model="apiKey" />
        </div>
        <div class="flex">
          <p>API Secret</p>
          <input type="text" v-model="apiSecret" />
        </div>
        <div class="flex">
          <p>온도 Trend key</p>
          <input type="text" v-model="tempKey" />
        </div>
        <div class="flex">
          <p>습도 Trend key</p>
          <input type="text" v-model="humKey" />
        </div>
        <div class="flex">
          <p>CO2 Trend key</p>
          <input type="text" v-model="co2Key" />
        </div>
        <div class="flex">
          <p>비고</p>
          <input type="text" v-model="etc" />
        </div>

        <div class="buttonWrap">
          <button class="basic" @click="submit">
            {{ editMode == true ? "수정" : "등록" }}
          </button>
          <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
        </div>

        <div class="modal" v-if="modalVisible">
          <div class="modal_bg" @click="handleModal"></div>
          <div class="modal_body">
            <div class="modal_header">
              <p>농장주 찾기</p>
              <button @click="handleModal">
                <span class="material-icons-round"> clear </span>
              </button>
            </div>
            <div class="modal_content">
              <div class="searchWrap">
                <input type="text" placeholder="농장주 이름을 입력해 주세요" v-model="searchWord" @keyup.enter="search" />
                <button @click="search">
                  <span class="material-icons-round"> search </span>
                </button>
              </div>
              <div class="tableBox" v-if="userList.length > 0">
                <table>
                  <tr>
                    <th>이름</th>
                    <th>연락처</th>
                  </tr>
                  <tr v-for="(data, i) in userList" :key="i" @click="userSelect(data)">
                    <td>{{ data.username }}</td>
                    <td>{{ getMask(data.mobileNo) }}</td>
                  </tr>
                </table>
                <NoData v-if="isNodata" />
                <div class="pagination" v-if="userList.length > 0">
                  <el-pagination layout="prev, pager, next" :total="total" :page-size="10" @current-change="handleCurrentChange"></el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserList, fetchUserDetails, fetchDeviceDetails, createDevice, upadateDeviceDetails, removeDevice } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "DeviceRegister",
  components: { NoData },

  data() {
    return {
      moment: moment,
      deviceId: "",
      farmer: "",
      farm: "",
      farmId: "",
      address: "",
      flag: "",
      mac_rn400: "",
      mac_rn171: "",
      etc: "",
      apiKey: "",
      tempKey: "",
      humKey: "",
      co2Key: "",
      apiSecret: "",
      searchWord: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      modalVisible: false,
      isNodata: false,
      currentPage: 1,
      total: 0,
      userList: [],
      farmList: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.deviceId = this.$route.query.id;
      this.getDeviceDetail();
    }
  },

  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    userSelect(farmer) {
      this.farmer = farmer;
      this.address = "";
      this.getUserDetail();
      this.handleModal();
    },
    search() {
      this.currentPage = 1;
      this.userList = [];
      this.total = 0;
      this.getUserList();
    },
    handleModal() {
      this.searchWord = "";
      this.currentPage = 1;
      this.userList = [];
      this.total = 0;
      this.modalVisible = !this.modalVisible;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeDevice(this.deviceId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "디바이스 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.go(-1);
          }
        });
      });
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        inputMode: "username",
        searchWord: this.searchWord,
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.users;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.currentPage = res.data.page;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    getUserDetail() {
      fetchUserDetails(this.farmer._id).then((res) => {
        if (res.data.status == 200) {
          this.farmList = res.data.userFarmList;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    getDeviceDetail() {
      fetchDeviceDetails(this.deviceId).then((res) => {
        if (res.data.status == 200) {
          this.farmer = res.data.data.farmer;
          this.farm = res.data.data.farm;
          this.farmId = res.data.data.farm ? res.data.data.farm._id : "";
          this.address = res.data.data.address;
          this.flag = res.data.data.flag;
          this.mac_rn400 = res.data.data.mac_rn400;
          this.mac_rn171 = res.data.data.mac_rn171;
          this.etc = res.data.data.etc;
          this.apiKey = res.data.data.apiKey;
          this.apiSecret = res.data.data.apiSecret;
          this.tempKey = res.data.data.tempKey;
          this.humKey = res.data.data.humKey;
          this.co2Key = res.data.data.co2Key;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.getUserDetail();
        } else {
          this.openAlert(res.data.message);
          return;
        }
      });
    },
    submit() {
      if (this.farmer == "") {
        this.openAlert("농장주를 입력해 주세요.");
        return;
      } else if (this.farmId == "") {
        this.openAlert("농장주소를 선택해 주세요.");
        return;
      } else if (this.flag == "") {
        this.openAlert("ID를 입력해 주세요.");
        return;
      } else if (this.mac_rn400 == "") {
        this.openAlert("MAC RN400을 입력해 주세요.");
        return;
      } else if (this.mac_rn171 == "") {
        this.openAlert("MAC RN171을 입력해 주세요.");
        return;
      } else if (this.apiKey == "") {
        this.openAlert("API Key를 입력해 주세요.");
        return;
      } else if (this.apiSecret == "") {
        this.openAlert("API Secret을 입력해 주세요.");
        return;
      }

      let data = {
        deviceId: this.deviceId,
        farmer: this.farmer._id,
        farm: this.farm ? this.farm._id : this.farmId,
        flag: this.flag,
        mac_rn400: this.mac_rn400,
        mac_rn171: this.mac_rn171,
        etc: this.etc,
        apiKey: this.apiKey,
        apiSecret: this.apiSecret,
        tempKey: this.tempKey,
        humKey: this.humKey,
        co2Key: this.co2Key,
      };
      if (this.editMode) {
        upadateDeviceDetails(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("디바이스 정보가 수정 되었습니다.");
            this.$router.push("/admin/devices");
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createDevice(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("디바이스 등록이 완료 되었습니다.");
            this.$router.push("/admin/devices");
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
    },
  },
};
</script>
